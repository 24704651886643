<template>
    <div>
        <v-container>
            <v-overlay :value="isLoading">
                <v-progress-circular :size="70" :width="7" indeterminate></v-progress-circular>
            </v-overlay>
            <!-- ============================================= -->
            <h2>{{ dbDevice.name }}</h2>
            <v-divider class="mb-3"></v-divider>
            <!-- ============================================= -->
            <v-row>
                <v-col cols="5">
                    <v-menu v-model="dispCalender" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field outlined dense v-model="targetDate" readonly v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="targetDate" @input="dispCalender = false" locale="ja" :max="maxDate" @change="reloadData()"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="4">
                    <v-select outlined dense v-model="daySpanValue" :items="daySpanOptions" @change="reloadData()"></v-select>
                </v-col>
            </v-row>
            <!-- ============================================= -->
            <v-row>
                <v-col>
                    <v-card>
                        <v-card-title class="blue darken-1 white--text"><h4>表層</h4></v-card-title>
                        <v-simple-table dense>
                            <thead class="blue darken-3 white--text text-center">
                                <tr>
                                    <td>日付</td>
                                    <td>最低水温</td>
                                    <td>平均水温</td>
                                    <td>最高水温</td>
                                </tr>
                            </thead>
                            <tbody class="text-center">
                                <tr v-for="item of topTempMinMaxAvr" :key="item.date">
                                    <td>{{ item.date }}</td>
                                    <td>{{ item.min }}</td>
                                    <td>{{ item.avr }}</td>
                                    <td>{{ item.max }}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </v-card>
                    <v-divider class="ma-5"></v-divider>
                    <v-card>
                        <v-card-title class="blue darken-1 white--text"><h4>中層</h4></v-card-title>
                        <v-simple-table dense>
                            <thead class="blue darken-3 white--text text-center">
                                <tr>
                                    <td>日付</td>
                                    <td>最低水温</td>
                                    <td>平均水温</td>
                                    <td>最高水温</td>
                                </tr>
                            </thead>
                            <tbody class="text-center">
                                <tr v-for="item of middleTempMinMaxAvr" :key="item.date">
                                    <td>{{ item.date }}</td>
                                    <td>{{ item.min }}</td>
                                    <td>{{ item.avr }}</td>
                                    <td>{{ item.max }}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </v-card>
                    <v-divider class="ma-5"></v-divider>
                    <v-card>
                        <v-card-title class="blue darken-1 white--text"><h4>深層</h4></v-card-title>
                        <v-simple-table dense>
                            <thead class="blue darken-3 white--text text-center">
                                <tr>
                                    <td>日付</td>
                                    <td>最低水温</td>
                                    <td>平均水温</td>
                                    <td>最高水温</td>
                                </tr>
                            </thead>
                            <tbody class="text-center">
                                <tr v-for="item of bottomTempMinMaxAvr" :key="item.date">
                                    <td>{{ item.date }}</td>
                                    <td>{{ item.min }}</td>
                                    <td>{{ item.avr }}</td>
                                    <td>{{ item.max }}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </v-card>
                </v-col>
            </v-row>
            <!-- ============================================= -->
            <!-- ============================================= -->
            <!-- ============================================= -->
        </v-container>
    </div>
</template>

<style></style>

<script>
export default {
    name: "AverageTemp",
    components: {},
    //========================================================
    data() {
        return {
            jwt: "",
            myInfo: [],
            windowWidth: window.innerWidth, // 画面サイズ
            windowHeight: window.innerHeight, //画面サイズ
            dispCalender: false,
            targetDate: this.$moment().format("YYYY-MM-DD"), //targetDate: "2020-08-21",
            maxDate: this.$moment().format("YYYY-MM-DD"),
            device_id: undefined,
            daySpanValue: 7,
            daySpanOptions: [
                { text: "7日間", value: 7 },
                { text: "14日間", value: 14 },
                { text: "30日間", value: 30 },
            ],
            dbDevices: [],
            dbDevice: {},
            dbSensorLogs: undefined,
            topTempMinMaxAvr: [
                // { date: "2024-07-30", min: 19.8, avr: 23.4, max: 28.6 },
                // { date: "2024-07-29", min: 20.1, avr: 22.8, max: 29.1 },
                // { date: "2024-07-28", min: 19.7, avr: 23.7, max: 28.4 },
                // { date: "2024-07-27", min: 20.3, avr: 22.9, max: 29.01 },
            ],
            middleTempMinMaxAvr: [],
            bottomTempMinMaxAvr: [],
            isLoading: false,
        };
    },
    //========================================================
    created: async function () {
        this.jwt = this.$localStorage.get("jwt");
        if (!this.jwt) {
            this.$router.push({ name: "Login" });
            return;
        }
        this.myInfo = this.$jwt.decode(this.jwt);
        if (this.myInfo && this.myInfo.exp < this.$moment().unix()) {
            this.$router.push({ name: "Login" });
            return;
        }
        //---------------------------------------------
        this.device_id = this.$route.params.device_id;
        if (this.$route.query.targetDate) this.targetDate = this.$route.query.targetDate;
        //---------------------------------------------
        //this.modalReset();
        this.isLoading = true;
        await this.getDevices();
        await this.reloadData();
        this.isLoading = false;
        window.scrollTo(0, 0);
    },
    //========================================================
    computed: {},
    //========================================================
    mounted: function () {
        window.addEventListener("resize", this.handleResize);
    },
    //====================================================
    beforeDestroy: function () {
        window.removeEventListener("resize", this.handleResize);
    },
    //========================================================
    methods: {
        //====================================================
        handleResize: function () {
            this.windowWidth = window.innerWidth;
            this.windowHeight = window.innerHeight;
        },
        //====================================================
        async getDevices() {
            await this.axios({
                method: "GET",
                url: "/web/api/devices",
                params: {
                    id: this.device_id,
                },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                this.dbDevices = response.data.json;
                this.dbDevice = response.data.json[0];
            });
        },
        //====================================================
        async getSensorData(sdate, edate) {
            let requestURL = "/web/api/sensor_logs";
            // requestURL += "?device_id=" + this.device_id;
            // requestURL += "&arrived_at[EQGREAT]=" + sdate;
            // requestURL += "&arrived_at[SMALL]=" + edate;
            // requestURL += "&_order[id]=asc";
            // requestURL += "&_fields=sensor_key,sensor_val,arrived_at";
            //----------------------------------------
            await this.axios({
                method: "GET",
                url: requestURL,
                params: {
                    "arrived_at[EQGREAT]": sdate,
                    "arrived_at[SMALL]": edate,
                    device_id: this.device_id,
                    sensor_grp: "water",
                    _fields: "sensor_key,sensor_val,arrived_at",
                },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                this.dbSensorLogs = response.data.json;
            });
        },
        //====================================================
        async reloadData() {
            //------------------------------------------
            function getAverage(arr) {
                let sum = 0;
                let ret = 0;
                for (let i = 0; i < arr.length; i++) {
                    sum += Number(arr[i]);
                }
                ret = sum / arr.length;
                ret = Math.round(ret * 10) / 10;
                return ret;
            }
            //------------------------------------------
            if (this.myInfo.exp < this.$moment().unix()) this.$router.push({ name: "Login" });
            this.isLoading = true;
            await new Promise((r) => setTimeout(r, 500));
            const sdays = (this.daySpanValue - 1) * -1;
            const sdate = this.$moment(this.targetDate).add(sdays, "days").format("YYYY-MM-DD");
            const edate = this.$moment(this.targetDate).add(1, "days").format("YYYY-MM-DD");
            await this.getSensorData(sdate, edate);
            //---------------------------------
            let topVals = {};
            let middleVals = {};
            let bottomVals = {};
            for (let obj of this.dbSensorLogs) {
                let date = obj.arrived_at;
                let key = obj.sensor_key;
                let val = obj.sensor_val;
                val = Number(val);
                //-----------------------
                if (val < 1) continue;
                //-----------------------
                date = this.$moment(date).format("YYYY-MM-DD");
                if (key == "top") {
                    if (!topVals[date]) topVals[date] = [];
                    topVals[date].push(val);
                }
                if (key == "middle") {
                    if (!middleVals[date]) middleVals[date] = [];
                    middleVals[date].push(val);
                }
                if (key == "bottom") {
                    if (!bottomVals[date]) bottomVals[date] = [];
                    bottomVals[date].push(val);
                }
            }
            //---------------------------------
            this.topTempMinMaxAvr = [];
            this.middleTempMinMaxAvr = [];
            this.bottomTempMinMaxAvr = [];
            let cdate = edate;

            cdate = edate;
            while (cdate >= sdate) {
                cdate = this.$moment(cdate).add(-1, "days").format("YYYY-MM-DD");
                if (!topVals[cdate]) continue;
                const min = Math.min(...topVals[cdate]);
                const max = Math.max(...topVals[cdate]);
                const avr = getAverage(topVals[cdate]);
                this.topTempMinMaxAvr.push({ date: cdate, min, avr, max });
            }

            cdate = edate;
            while (cdate >= sdate) {
                cdate = this.$moment(cdate).add(-1, "days").format("YYYY-MM-DD");
                if (!middleVals[cdate]) continue;
                const min = Math.min(...middleVals[cdate]);
                const max = Math.max(...middleVals[cdate]);
                const avr = getAverage(middleVals[cdate]);
                this.middleTempMinMaxAvr.push({ date: cdate, min, avr, max });
            }

            cdate = edate;
            while (cdate >= sdate) {
                cdate = this.$moment(cdate).add(-1, "days").format("YYYY-MM-DD");
                if (!bottomVals[cdate]) continue;
                const min = Math.min(...bottomVals[cdate]);
                const max = Math.max(...bottomVals[cdate]);
                const avr = getAverage(bottomVals[cdate]);
                this.bottomTempMinMaxAvr.push({ date: cdate, min, avr, max });
            }
            //---------------------------------
            this.isLoading = false;
        },
        //====================================================
    },
};
</script>
