<template>
    <v-container>
        <v-overlay :value="isLoading">
            <v-progress-circular :size="70" :width="7" indeterminate></v-progress-circular>
        </v-overlay>
        <!-- ============================================= -->
        <h2>{{ dbDevice.name }}</h2>
        <v-divider class="mb-3"></v-divider>
        <!-- ============================================= -->
        <v-row>
            <v-col cols="5">
                <v-menu v-model="dispCalender" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field outlined dense v-model="targetDate" readonly v-bind="attrs" v-on="on" background-color="white"></v-text-field>
                    </template>
                    <v-date-picker v-model="targetDate" @input="dispCalender = false" locale="ja" :max="maxDate" @change="axiosGetWinchSummary()"></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="4">
                <v-select outlined dense v-model="daySpanValue" :items="daySpanOptions" @change="axiosGetWinchSummary()"></v-select>
            </v-col>
        </v-row>
        <!-- ============================================= -->

        <!-- ################# 水温 ##################################### -->
        <v-card class="overflow-x-auto pa-0 ma-0" elevation="1">
            <v-card-title class="blue darken-2 pa-2"><h4 class="white--text">水温 (℃)</h4></v-card-title>
            <div class="white chart-container" style="position: relative; width: 100vw; height: auto; overflow: hidden">
                <comp-chart-winch-water :chartDataChild="chartDataSetWater" :yRange="chartRange.water" style="height: 60vh" />
            </div>
        </v-card>
        <div class="ma-5"></div>
        <!-- ################## DO ################################### -->
        <v-card class="overflow-x-auto pa-0 ma-0" elevation="1">
            <v-card-title class="blue darken-2 pa-2"><h4 class="white--text">DO (mg/L)</h4></v-card-title>
            <div class="chart-container" style="position: relative; width: 100vw; height: auto; overflow: hidden">
                <comp-chart-oxygen :chartDataChild="chartDataSetOxygen" :yRange="chartRange.oxygen" style="height: 60vh" />
            </div>
        </v-card>
        <div class="ma-5"></div>
        <!-- ################## 実用 塩分 #################################### -->
        <v-card class="overflow-x-auto pa-0 ma-0" elevation="1">
            <v-card-title class="blue darken-2 pa-2"><h4 class="white--text">塩分</h4></v-card-title>
            <div class="chart-container" style="position: relative; width: 100vw; height: auto; overflow: hidden">
                <comp-chart-salinity :chartDataChild="chartDataSetSalinity" :yRange="chartRange.salinity" style="height: 60vh" />
            </div>
        </v-card>
        <div class="ma-5"></div>
        <!-- ################# クロロ ##################################### -->
        <v-card class="overflow-x-auto pa-0 ma-0" elevation="1">
            <v-card-title class="blue darken-2 pa-2"><h4 class="white--text">クロロフィル (ppb)</h4></v-card-title>
            <div class="chart-container" style="position: relative; width: 100vw; height: auto; overflow: hidden">
                <comp-chart-chloro :chartDataChild="chartDataSetChloro" :yRange="chartRange.chlorophyll" style="height: 60vh" />
            </div>
        </v-card>
        <div class="ma-5"></div>
        <!-- ################# 濁度 ##################################### -->
        <v-card class="overflow-x-auto pa-0 ma-0" elevation="1">
            <v-card-title class="blue darken-2 pa-2"><h4 class="white--text">濁度 (FTU)</h4></v-card-title>
            <div class="chart-container" style="position: relative; width: 100vw; height: auto; overflow: hidden">
                <comp-chart-turbidity :chartDataChild="chartDataSetTurbidity" :yRange="chartRange.turbidity" style="height: 60vh" />
            </div>
        </v-card>
        <div class="ma-5"></div>
        <!-- ########################################################### -->
        <!-- =============================================
        <v-divider></v-divider>
        <v-card class="text-center py-3">
            <div class="mx-3 overflow-x-auto">
                <v-btn-toggle v-model="deepBtn" color="deep-purple accent-3" dense mandatory @change="createChartData()">
                    <v-btn value="1">水深1m</v-btn>
                    <v-btn value="2">水深2m</v-btn>
                    <v-btn value="3">水深3m</v-btn>
                    <v-btn value="4">水深4m</v-btn>
                    <v-btn value="5">水深5m</v-btn>
                    <v-btn value="6">水深6m</v-btn>
                    <v-btn value="7">水深7m</v-btn>
                    <v-btn value="8">水深8m</v-btn>
                    <v-btn value="9">水深9m</v-btn>
                    <v-btn value="10">水深10m</v-btn>
                </v-btn-toggle>
            </div>
        </v-card>
        ============================================= -->
    </v-container>
</template>

<script>
import CompChartWinchWater from "@/components/CompChartWinchWater";
import CompChartChloro from "@/components/CompChartChloro";
import CompChartTurbidity from "@/components/CompChartTurbidity";
import CompChartSalinity from "@/components/CompChartSalinity";
import CompChartOxygen from "@/components/CompChartOxygen";

export default {
    name: "Playback",
    components: {
        CompChartWinchWater,
        CompChartChloro,
        CompChartTurbidity,
        CompChartSalinity,
        CompChartOxygen,
    },
    //##################################################################################
    data() {
        return {
            jwt: "",
            myInfo: [],
            device_id: 0,
            dbDevice: { id: 0, name: "" },
            dbWinchSummary: [],
            deepBtn: 0,
            dispCalender: false,
            targetDate: this.$moment().format("YYYY-MM-DD"),
            maxDate: this.$moment().format("YYYY-MM-DD"),
            isLoading: false,
            //---------------------------------
            chartDataSetWater: { datasets: [] },
            chartDataSetChloro: { datasets: [] },
            chartDataSetTurbidity: { datasets: [] },
            chartDataSetSalinity: { datasets: [] },
            chartDataSetSalinitySG: { datasets: [] },
            chartDataSetOxygen: { datasets: [] },
            //---------------------------------
            daySpanValue: 2,
            daySpanOptions: [
                { text: "1日間", value: 1 },
                { text: "2日間", value: 2 },
                { text: "7日間", value: 7 },
            ],
            //---------------------------------
            chartRange: {
                water: { min: 0, max: 0 },
                chlorophyll: { min: 0, max: 0 },
                turbidity: { min: 0, max: 0 },
                salinity: { min: 0, max: 0 },
                oxygen: { min: 0, max: 0 },
            },
            //---------------------------------
            chartColor: {
                water_A: "#7fd1ea",
                water_B: "#386ddd",
                water_C: "#000d88",
                water_D: "#000d88",
                water_E: "#000d88",
                salinity_A: "#bbbbbb",
                salinity_B: "#808080",
                salinity_C: "#2d2d2d",
                salinity_D: "#2d2d2d",
                salinity_E: "#2d2d2d",
                oxygen_A: "#a3c4ff",
                oxygen_B: "#7b7bc1",
                oxygen_C: "#545484",
                oxygen_D: "#545484",
                oxygen_E: "#545484",
                chlorophyll_A: "#87f098",
                chlorophyll_B: "#8fbc8f",
                chlorophyll_C: "#006400",
                chlorophyll_D: "#006400",
                chlorophyll_E: "#006400",
                turbidity_A: "#daa520",
                turbidity_B: "#b8860b",
                turbidity_C: "#aa1529",
                turbidity_D: "#aa1529",
                turbidity_E: "#aa1529",
            },
            //---------------------------------
            chartLabel: {
                water_A: "水温",
                water_B: "水温",
                water_C: "水温",
                water_D: "水温",
                water_E: "水温",
                salinity_A: "塩分",
                salinity_B: "塩分",
                salinity_C: "塩分",
                salinity_D: "塩分",
                salinity_E: "塩分",
                oxygen_A: "DO",
                oxygen_B: "DO",
                oxygen_C: "DO",
                oxygen_D: "DO",
                oxygen_E: "DO",
                chlorophyll_A: "クロロ",
                chlorophyll_B: "クロロ",
                chlorophyll_C: "クロロ",
                chlorophyll_D: "クロロ",
                chlorophyll_E: "クロロ",
                turbidity_A: "濁度",
                turbidity_B: "濁度",
                turbidity_C: "濁度",
                turbidity_D: "濁度",
                turbidity_E: "濁度",
            },
            //---------------------------------
        };
    },
    //##################################################################################
    created: async function () {
        this.jwt = this.$localStorage.get("jwt");
        if (!this.jwt) {
            this.$router.push({ name: "Login" });
            return;
        }
        this.myInfo = this.$jwt.decode(this.jwt);
        if (this.myInfo && this.myInfo.exp < this.$moment().unix()) {
            this.$router.push({ name: "Login" });
            return;
        }
        //-----------------------
        this.device_id = this.$route.params.device_id;
        this.localRangeUpdate();
        await this.axiosGetDevice();
        await this.axiosGetWinchSummary();
        //-----------------------
        window.scrollTo(0, 0);
        await new Promise((r) => setTimeout(r, 3000));
        //-----------------------
    },
    //##################################################################################
    beforeDestroy: function () {},
    //##################################################################################
    mounted: function () {},
    //##################################################################################
    watch: {
        $route: async function (to, from) {
            if (from.fullPath !== to.fullPath) {
                this.device_id = to.params.device_id;
                this.axiosGetDevice();
                await this.axiosGetWinchSummary();
            }
        },
    },
    //##################################################################################
    methods: {
        //====================================================
        async localRangeUpdate() {
            if (this.$localStorage.get("chart.range.json") != undefined) {
                let localRange = JSON.parse(this.$localStorage.get("chart.range.json"));
                if (Number(localRange["water"]["min"])) this.chartRange.water.min = Number(localRange["water"]["min"]);
                if (Number(localRange["water"]["max"])) this.chartRange.water.max = Number(localRange["water"]["max"]);
                if (Number(localRange["salinity"]["min"])) this.chartRange.salinity.min = Number(localRange["salinity"]["min"]);
                if (Number(localRange["salinity"]["max"])) this.chartRange.salinity.max = Number(localRange["salinity"]["max"]);
                if (Number(localRange["oxygen"]["min"])) this.chartRange.oxygen.min = Number(localRange["oxygen"]["min"]);
                if (Number(localRange["oxygen"]["max"])) this.chartRange.oxygen.max = Number(localRange["oxygen"]["max"]);
                if (Number(localRange["chlorophyll"]["min"])) this.chartRange.chlorophyll.min = Number(localRange["chlorophyll"]["min"]);
                if (Number(localRange["chlorophyll"]["max"])) this.chartRange.chlorophyll.max = Number(localRange["chlorophyll"]["max"]);
                if (Number(localRange["turbidity"]["min"])) this.chartRange.turbidity.min = Number(localRange["turbidity"]["min"]);
                if (Number(localRange["turbidity"]["max"])) this.chartRange.turbidity.max = Number(localRange["turbidity"]["max"]);
            }
        },
        //====================================================
        async axiosGetDevice() {
            await this.axios({
                method: "GET",
                url: "/web/api/devices",
                params: {
                    id: this.device_id,
                },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                this.dbDevice = response.data.json[0];
            });
        },
        //====================================================
        async axiosGetWinchSummary() {
            this.isLoading = true;
            //------------------------
            await new Promise((r) => setTimeout(r, 100 * this.daySpanValue));
            //------------------------
            const sdays = (this.daySpanValue - 1) * -1;
            const sdate = this.$moment(this.targetDate).add(sdays, "days").format("YYYY-MM-DD");
            const edate = this.$moment(this.targetDate).add(0, "days").format("YYYY-MM-DD");
            await this.axios({
                method: "GET",
                url: "/web/api/winch-summary/" + this.device_id + "/" + sdate + "/" + edate,
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            })
                .then((response) => {
                    this.dbWinchSummary = response.data.json;
                    this.createChartData();
                    this.isLoading = false;
                })
                .catch((err) => {
                    this.isLoading = false;
                    const { status, statusText } = err.response;
                    alert(status + " : " + statusText);
                });
        },
        //====================================================
        createChartData() {
            //-----------------------------------------
            this.chartDataSetWater = { datasets: [] };
            this.chartDataSetOxygen = { datasets: [] };
            this.chartDataSetSalinity = { datasets: [] };
            this.chartDataSetChloro = { datasets: [] };
            this.chartDataSetTurbidity = { datasets: [] };
            let water_A = [];
            let water_B = [];
            let water_C = [];
            let water_D = [];
            let water_E = [];
            let water_F = [];
            let water_G = [];
            let water_H = [];
            let water_I = [];
            let water_J = [];
            let oxygen_A = [];
            let oxygen_B = [];
            let oxygen_C = [];
            let oxygen_D = [];
            let oxygen_E = [];
            let oxygen_F = [];
            let oxygen_G = [];
            let oxygen_H = [];
            let oxygen_I = [];
            let oxygen_J = [];
            let salinity_A = [];
            let salinity_B = [];
            let salinity_C = [];
            let salinity_D = [];
            let salinity_E = [];
            let salinity_F = [];
            let salinity_G = [];
            let salinity_H = [];
            let salinity_I = [];
            let salinity_J = [];
            let chlorophyll_A = [];
            let chlorophyll_B = [];
            let chlorophyll_C = [];
            let chlorophyll_D = [];
            let chlorophyll_E = [];
            let chlorophyll_F = [];
            let chlorophyll_G = [];
            let chlorophyll_H = [];
            let chlorophyll_I = [];
            let chlorophyll_J = [];
            let turbidity_A = [];
            let turbidity_B = [];
            let turbidity_C = [];
            let turbidity_D = [];
            let turbidity_E = [];
            let turbidity_F = [];
            let turbidity_G = [];
            let turbidity_H = [];
            let turbidity_I = [];
            let turbidity_J = [];
            for (let rec of this.dbWinchSummary) {
                const time = rec["sensor_time"];
                const deep = rec["sensor_deep"];
                const key = rec["sensor_key"];
                const val = rec["sensor_val"];
                if (deep == 1 && key == "water_temp") water_A.push({ x: time, y: val });
                if (deep == 2 && key == "water_temp") water_B.push({ x: time, y: val });
                if (deep == 3 && key == "water_temp") water_C.push({ x: time, y: val });
                if (deep == 4 && key == "water_temp") water_D.push({ x: time, y: val });
                if (deep == 5 && key == "water_temp") water_E.push({ x: time, y: val });
                if (deep == 6 && key == "water_temp") water_F.push({ x: time, y: val });
                if (deep == 7 && key == "water_temp") water_G.push({ x: time, y: val });
                if (deep == 8 && key == "water_temp") water_H.push({ x: time, y: val });
                if (deep == 9 && key == "water_temp") water_I.push({ x: time, y: val });
                if (deep == 10 && key == "water_temp") water_J.push({ x: time, y: val });

                if (deep == 1 && key == "oxygen") oxygen_A.push({ x: time, y: val });
                if (deep == 2 && key == "oxygen") oxygen_B.push({ x: time, y: val });
                if (deep == 3 && key == "oxygen") oxygen_C.push({ x: time, y: val });
                if (deep == 4 && key == "oxygen") oxygen_D.push({ x: time, y: val });
                if (deep == 5 && key == "oxygen") oxygen_E.push({ x: time, y: val });
                if (deep == 6 && key == "oxygen") oxygen_F.push({ x: time, y: val });
                if (deep == 7 && key == "oxygen") oxygen_G.push({ x: time, y: val });
                if (deep == 8 && key == "oxygen") oxygen_H.push({ x: time, y: val });
                if (deep == 9 && key == "oxygen") oxygen_I.push({ x: time, y: val });
                if (deep == 10 && key == "oxygen") oxygen_J.push({ x: time, y: val });

                if (deep == 1 && key == "salinity") salinity_A.push({ x: time, y: val });
                if (deep == 2 && key == "salinity") salinity_B.push({ x: time, y: val });
                if (deep == 3 && key == "salinity") salinity_C.push({ x: time, y: val });
                if (deep == 4 && key == "salinity") salinity_D.push({ x: time, y: val });
                if (deep == 5 && key == "salinity") salinity_E.push({ x: time, y: val });
                if (deep == 6 && key == "salinity") salinity_F.push({ x: time, y: val });
                if (deep == 7 && key == "salinity") salinity_G.push({ x: time, y: val });
                if (deep == 8 && key == "salinity") salinity_H.push({ x: time, y: val });
                if (deep == 9 && key == "salinity") salinity_I.push({ x: time, y: val });
                if (deep == 10 && key == "salinity") salinity_J.push({ x: time, y: val });

                if (deep == 1 && key == "chlorophyll") chlorophyll_A.push({ x: time, y: val });
                if (deep == 2 && key == "chlorophyll") chlorophyll_B.push({ x: time, y: val });
                if (deep == 3 && key == "chlorophyll") chlorophyll_C.push({ x: time, y: val });
                if (deep == 4 && key == "chlorophyll") chlorophyll_D.push({ x: time, y: val });
                if (deep == 5 && key == "chlorophyll") chlorophyll_E.push({ x: time, y: val });
                if (deep == 6 && key == "chlorophyll") chlorophyll_F.push({ x: time, y: val });
                if (deep == 7 && key == "chlorophyll") chlorophyll_G.push({ x: time, y: val });
                if (deep == 8 && key == "chlorophyll") chlorophyll_H.push({ x: time, y: val });
                if (deep == 9 && key == "chlorophyll") chlorophyll_I.push({ x: time, y: val });
                if (deep == 10 && key == "chlorophyll") chlorophyll_J.push({ x: time, y: val });

                if (deep == 1 && key == "turbidity") turbidity_A.push({ x: time, y: val });
                if (deep == 2 && key == "turbidity") turbidity_B.push({ x: time, y: val });
                if (deep == 3 && key == "turbidity") turbidity_C.push({ x: time, y: val });
                if (deep == 4 && key == "turbidity") turbidity_D.push({ x: time, y: val });
                if (deep == 5 && key == "turbidity") turbidity_E.push({ x: time, y: val });
                if (deep == 6 && key == "turbidity") turbidity_F.push({ x: time, y: val });
                if (deep == 7 && key == "turbidity") turbidity_G.push({ x: time, y: val });
                if (deep == 8 && key == "turbidity") turbidity_H.push({ x: time, y: val });
                if (deep == 9 && key == "turbidity") turbidity_I.push({ x: time, y: val });
                if (deep == 10 && key == "turbidity") turbidity_J.push({ x: time, y: val });
            }
            //-----------------------------------------
            const sdays = (this.daySpanValue - 1) * -1;
            const sdate = this.$moment(this.targetDate).add(sdays, "days").format("YYYY-MM-DD 00:00");
            const edate = this.$moment(this.targetDate).add(1, "days").format("YYYY-MM-DD 00:00");
            water_A.unshift({ x: sdate, y: null });
            water_B.unshift({ x: sdate, y: null });
            water_C.unshift({ x: sdate, y: null });
            water_D.unshift({ x: sdate, y: null });
            water_E.unshift({ x: sdate, y: null });
            water_F.unshift({ x: sdate, y: null });
            water_G.unshift({ x: sdate, y: null });
            water_H.unshift({ x: sdate, y: null });
            water_I.unshift({ x: sdate, y: null });
            water_J.unshift({ x: sdate, y: null });
            water_A.push({ x: edate, y: null });
            water_B.push({ x: edate, y: null });
            water_C.push({ x: edate, y: null });
            water_D.push({ x: edate, y: null });
            water_E.push({ x: edate, y: null });
            water_F.push({ x: edate, y: null });
            water_G.push({ x: edate, y: null });
            water_H.push({ x: edate, y: null });
            water_I.push({ x: edate, y: null });
            water_J.push({ x: edate, y: null });

            oxygen_A.unshift({ x: sdate, y: null });
            oxygen_B.unshift({ x: sdate, y: null });
            oxygen_C.unshift({ x: sdate, y: null });
            oxygen_D.unshift({ x: sdate, y: null });
            oxygen_E.unshift({ x: sdate, y: null });
            oxygen_F.unshift({ x: sdate, y: null });
            oxygen_G.unshift({ x: sdate, y: null });
            oxygen_H.unshift({ x: sdate, y: null });
            oxygen_I.unshift({ x: sdate, y: null });
            oxygen_J.unshift({ x: sdate, y: null });
            oxygen_A.push({ x: edate, y: null });
            oxygen_B.push({ x: edate, y: null });
            oxygen_C.push({ x: edate, y: null });
            oxygen_D.push({ x: edate, y: null });
            oxygen_E.push({ x: edate, y: null });
            oxygen_F.push({ x: edate, y: null });
            oxygen_G.push({ x: edate, y: null });
            oxygen_H.push({ x: edate, y: null });
            oxygen_I.push({ x: edate, y: null });
            oxygen_J.push({ x: edate, y: null });

            salinity_A.unshift({ x: sdate, y: null });
            salinity_B.unshift({ x: sdate, y: null });
            salinity_C.unshift({ x: sdate, y: null });
            salinity_D.unshift({ x: sdate, y: null });
            salinity_E.unshift({ x: sdate, y: null });
            salinity_F.unshift({ x: sdate, y: null });
            salinity_G.unshift({ x: sdate, y: null });
            salinity_H.unshift({ x: sdate, y: null });
            salinity_I.unshift({ x: sdate, y: null });
            salinity_J.unshift({ x: sdate, y: null });
            salinity_A.push({ x: edate, y: null });
            salinity_B.push({ x: edate, y: null });
            salinity_C.push({ x: edate, y: null });
            salinity_D.push({ x: edate, y: null });
            salinity_E.push({ x: edate, y: null });
            salinity_F.push({ x: edate, y: null });
            salinity_G.push({ x: edate, y: null });
            salinity_H.push({ x: edate, y: null });
            salinity_I.push({ x: edate, y: null });
            salinity_J.push({ x: edate, y: null });

            chlorophyll_A.unshift({ x: sdate, y: null });
            chlorophyll_B.unshift({ x: sdate, y: null });
            chlorophyll_C.unshift({ x: sdate, y: null });
            chlorophyll_D.unshift({ x: sdate, y: null });
            chlorophyll_E.unshift({ x: sdate, y: null });
            chlorophyll_F.unshift({ x: sdate, y: null });
            chlorophyll_G.unshift({ x: sdate, y: null });
            chlorophyll_H.unshift({ x: sdate, y: null });
            chlorophyll_I.unshift({ x: sdate, y: null });
            chlorophyll_J.unshift({ x: sdate, y: null });
            chlorophyll_A.push({ x: edate, y: null });
            chlorophyll_B.push({ x: edate, y: null });
            chlorophyll_C.push({ x: edate, y: null });
            chlorophyll_D.push({ x: edate, y: null });
            chlorophyll_E.push({ x: edate, y: null });
            chlorophyll_F.push({ x: edate, y: null });
            chlorophyll_G.push({ x: edate, y: null });
            chlorophyll_H.push({ x: edate, y: null });
            chlorophyll_I.push({ x: edate, y: null });
            chlorophyll_J.push({ x: edate, y: null });

            turbidity_A.unshift({ x: sdate, y: null });
            turbidity_B.unshift({ x: sdate, y: null });
            turbidity_C.unshift({ x: sdate, y: null });
            turbidity_D.unshift({ x: sdate, y: null });
            turbidity_E.unshift({ x: sdate, y: null });
            turbidity_F.unshift({ x: sdate, y: null });
            turbidity_G.unshift({ x: sdate, y: null });
            turbidity_H.unshift({ x: sdate, y: null });
            turbidity_I.unshift({ x: sdate, y: null });
            turbidity_J.unshift({ x: sdate, y: null });
            turbidity_A.push({ x: edate, y: null });
            turbidity_B.push({ x: edate, y: null });
            turbidity_C.push({ x: edate, y: null });
            turbidity_D.push({ x: edate, y: null });
            turbidity_E.push({ x: edate, y: null });
            turbidity_F.push({ x: edate, y: null });
            turbidity_G.push({ x: edate, y: null });
            turbidity_H.push({ x: edate, y: null });
            turbidity_I.push({ x: edate, y: null });
            turbidity_J.push({ x: edate, y: null });

            //-----------------------------------------
            // 彩度別の色見本
            // https://www.color-site.com/separate_chromas
            //-----------------------------------------
            this.chartDataSetWater.datasets.push({ label: "水温(1m)", data: water_A, borderColor: "#e2e3fb", borderWidth: 2, pointRadius: 1.2, fill: false });
            this.chartDataSetWater.datasets.push({ label: "水温(2m)", data: water_B, borderColor: "#c9cbfb", borderWidth: 2, pointRadius: 1.2, fill: false });
            this.chartDataSetWater.datasets.push({ label: "水温(3m)", data: water_C, borderColor: "#b0b3fb", borderWidth: 2, pointRadius: 1.2, fill: false });
            this.chartDataSetWater.datasets.push({ label: "水温(4m)", data: water_D, borderColor: "#979bfb", borderWidth: 2, pointRadius: 1.2, fill: false });
            this.chartDataSetWater.datasets.push({ label: "水温(5m)", data: water_E, borderColor: "#7d82fb", borderWidth: 2, pointRadius: 1.2, fill: false });
            this.chartDataSetWater.datasets.push({ label: "水温(6m)", data: water_F, borderColor: "#646afb", borderWidth: 2, pointRadius: 1.2, fill: false });
            this.chartDataSetWater.datasets.push({ label: "水温(7m)", data: water_G, borderColor: "#4b52fb", borderWidth: 2, pointRadius: 1.2, fill: false });
            this.chartDataSetWater.datasets.push({ label: "水温(8m)", data: water_H, borderColor: "#323afb", borderWidth: 2, pointRadius: 1.2, fill: false });
            this.chartDataSetWater.datasets.push({ label: "水温(9m)", data: water_I, borderColor: "#1922fb", borderWidth: 2, pointRadius: 1.2, fill: false });
            this.chartDataSetWater.datasets.push({ label: "水温(10m)", data: water_J, borderColor: "#000afb", borderWidth: 2, pointRadius: 1.2, fill: false });

            this.chartDataSetOxygen.datasets.push({ label: "DO(1m)", data: oxygen_A, borderColor: "#fbf0e2", borderWidth: 2, pointRadius: 1.2, fill: false });
            this.chartDataSetOxygen.datasets.push({ label: "DO(2m)", data: oxygen_B, borderColor: "#fbe6c9", borderWidth: 2, pointRadius: 1.2, fill: false });
            this.chartDataSetOxygen.datasets.push({ label: "DO(3m)", data: oxygen_C, borderColor: "#fbdbb0", borderWidth: 2, pointRadius: 1.2, fill: false });
            this.chartDataSetOxygen.datasets.push({ label: "DO(4m)", data: oxygen_D, borderColor: "#fbd097", borderWidth: 2, pointRadius: 1.2, fill: false });
            this.chartDataSetOxygen.datasets.push({ label: "DO(5m)", data: oxygen_E, borderColor: "#fbc67d", borderWidth: 2, pointRadius: 1.2, fill: false });
            this.chartDataSetOxygen.datasets.push({ label: "DO(6m)", data: oxygen_F, borderColor: "#fbbb64", borderWidth: 2, pointRadius: 1.2, fill: false });
            this.chartDataSetOxygen.datasets.push({ label: "DO(7m)", data: oxygen_G, borderColor: "#fbb04b", borderWidth: 2, pointRadius: 1.2, fill: false });
            this.chartDataSetOxygen.datasets.push({ label: "DO(8m)", data: oxygen_H, borderColor: "#fba632", borderWidth: 2, pointRadius: 1.2, fill: false });
            this.chartDataSetOxygen.datasets.push({ label: "DO(9m)", data: oxygen_I, borderColor: "#fb9b19", borderWidth: 2, pointRadius: 1.2, fill: false });
            this.chartDataSetOxygen.datasets.push({ label: "DO(10m)", data: oxygen_J, borderColor: "#fb9100", borderWidth: 2, pointRadius: 1.2, fill: false });

            this.chartDataSetSalinity.datasets.push({ label: "塩分(1m)", data: salinity_A, borderColor: "#d0d2e7", borderWidth: 2, pointRadius: 1.2, fill: false });
            this.chartDataSetSalinity.datasets.push({ label: "塩分(2m)", data: salinity_B, borderColor: "#b8bce7", borderWidth: 2, pointRadius: 1.2, fill: false });
            this.chartDataSetSalinity.datasets.push({ label: "塩分(3m)", data: salinity_C, borderColor: "#a2a7e7", borderWidth: 2, pointRadius: 1.2, fill: false });
            this.chartDataSetSalinity.datasets.push({ label: "塩分(4m)", data: salinity_D, borderColor: "#8a92e7", borderWidth: 2, pointRadius: 1.2, fill: false });
            this.chartDataSetSalinity.datasets.push({ label: "塩分(5m)", data: salinity_E, borderColor: "#737de7", borderWidth: 2, pointRadius: 1.2, fill: false });
            this.chartDataSetSalinity.datasets.push({ label: "塩分(6m)", data: salinity_F, borderColor: "#5c68e7", borderWidth: 2, pointRadius: 1.2, fill: false });
            this.chartDataSetSalinity.datasets.push({ label: "塩分(7m)", data: salinity_G, borderColor: "#4553e7", borderWidth: 2, pointRadius: 1.2, fill: false });
            this.chartDataSetSalinity.datasets.push({ label: "塩分(8m)", data: salinity_H, borderColor: "#2e3ee7", borderWidth: 2, pointRadius: 1.2, fill: false });
            this.chartDataSetSalinity.datasets.push({ label: "塩分(9m)", data: salinity_I, borderColor: "#1728e7", borderWidth: 2, pointRadius: 1.2, fill: false });
            this.chartDataSetSalinity.datasets.push({ label: "塩分(10m)", data: salinity_J, borderColor: "#0013e7", borderWidth: 2, pointRadius: 1.2, fill: false });

            this.chartDataSetChloro.datasets.push({ label: "クロロ(1m)", data: chlorophyll_A, borderColor: "#d3e6cf", borderWidth: 2, pointRadius: 1.2, fill: false });
            this.chartDataSetChloro.datasets.push({ label: "クロロ(2m)", data: chlorophyll_B, borderColor: "#c0e6b8", borderWidth: 2, pointRadius: 1.2, fill: false });
            this.chartDataSetChloro.datasets.push({ label: "クロロ(3m)", data: chlorophyll_C, borderColor: "#ade6a1", borderWidth: 2, pointRadius: 1.2, fill: false });
            this.chartDataSetChloro.datasets.push({ label: "クロロ(4m)", data: chlorophyll_D, borderColor: "#9ae68a", borderWidth: 2, pointRadius: 1.2, fill: false });
            this.chartDataSetChloro.datasets.push({ label: "クロロ(5m)", data: chlorophyll_E, borderColor: "#87e673", borderWidth: 2, pointRadius: 1.2, fill: false });
            this.chartDataSetChloro.datasets.push({ label: "クロロ(6m)", data: chlorophyll_F, borderColor: "#73e65c", borderWidth: 2, pointRadius: 1.2, fill: false });
            this.chartDataSetChloro.datasets.push({ label: "クロロ(7m)", data: chlorophyll_G, borderColor: "#60e645", borderWidth: 2, pointRadius: 1.2, fill: false });
            this.chartDataSetChloro.datasets.push({ label: "クロロ(8m)", data: chlorophyll_H, borderColor: "#4de62e", borderWidth: 2, pointRadius: 1.2, fill: false });
            this.chartDataSetChloro.datasets.push({ label: "クロロ(9m)", data: chlorophyll_I, borderColor: "#3ae617", borderWidth: 2, pointRadius: 1.2, fill: false });
            this.chartDataSetChloro.datasets.push({ label: "クロロ(10m)", data: chlorophyll_J, borderColor: "#27e600", borderWidth: 2, pointRadius: 1.2, fill: false });

            this.chartDataSetTurbidity.datasets.push({ label: "濁度(1m)", data: turbidity_A, borderColor: "#e7dad0", borderWidth: 2, pointRadius: 1.2, fill: false });
            this.chartDataSetTurbidity.datasets.push({ label: "濁度(2m)", data: turbidity_B, borderColor: "#e7ceb9", borderWidth: 2, pointRadius: 1.2, fill: false });
            this.chartDataSetTurbidity.datasets.push({ label: "濁度(3m)", data: turbidity_C, borderColor: "#e7c1a2", borderWidth: 2, pointRadius: 1.2, fill: false });
            this.chartDataSetTurbidity.datasets.push({ label: "濁度(4m)", data: turbidity_D, borderColor: "#e7b58a", borderWidth: 2, pointRadius: 1.2, fill: false });
            this.chartDataSetTurbidity.datasets.push({ label: "濁度(5m)", data: turbidity_E, borderColor: "#e7a973", borderWidth: 2, pointRadius: 1.2, fill: false });
            this.chartDataSetTurbidity.datasets.push({ label: "濁度(6m)", data: turbidity_F, borderColor: "#e79c5c", borderWidth: 2, pointRadius: 1.2, fill: false });
            this.chartDataSetTurbidity.datasets.push({ label: "濁度(7m)", data: turbidity_G, borderColor: "#e79045", borderWidth: 2, pointRadius: 1.2, fill: false });
            this.chartDataSetTurbidity.datasets.push({ label: "濁度(8m)", data: turbidity_H, borderColor: "#e7832e", borderWidth: 2, pointRadius: 1.2, fill: false });
            this.chartDataSetTurbidity.datasets.push({ label: "濁度(9m)", data: turbidity_I, borderColor: "#e77717", borderWidth: 2, pointRadius: 1.2, fill: false });
            this.chartDataSetTurbidity.datasets.push({ label: "濁度(10m)", data: turbidity_J, borderColor: "#e76a00", borderWidth: 2, pointRadius: 1.2, fill: false });
        },
        //====================================================
    },
};
</script>
