<template>
    <v-container fluid>
        <!-- =============================== -->
        <div class="my-5">
            <v-btn block @click="editItemDialog(null)" color="success"><h2>追加</h2></v-btn>
        </div>
        <!-- =============================== -->
        <v-card v-for="item in dbNotificationConfigs" :key="item.id" class="my-5">
            <v-app-bar dark color="blue darken-3">
                <v-app-bar-title>
                    <h3>{{ item.device_name }}</h3>
                </v-app-bar-title>
                <v-spacer></v-spacer>
                <v-btn color="success" @click="editItemDialog(item)"> <v-icon small>mdi-pencil</v-icon>編集 </v-btn>
            </v-app-bar>
            <v-card-text class="yellow lighten-5">
                <h2>{{ item.sensor_name }}［ {{ item.limit_val }} ］</h2>
                <h4>（ {{ item.upper_lower_name }} ）</h4>
                <v-divider class="my-3"></v-divider>
                <h4>再通知： {{ item.silence_hour }} 時間後</h4>
                <v-divider class="my-3"></v-divider>
                <h4>メモ：{{ item.memo }}</h4>
                <v-divider class="my-3"></v-divider>
                <h4>前回通知日時：{{ item.sent_at }}</h4>
                <v-divider class="my-3"></v-divider>
                <v-btn color="blue darken-2" text @click="deleteItemConfirmDialog(item)" class="pa-0"><v-icon>mdi-delete</v-icon>通知設定削除</v-btn>
            </v-card-text>
        </v-card>
        <!-- =============================== -->
        <v-dialog v-model="dialog" persistent max-width="500px">
            <v-card>
                <v-app-bar dark color="primary">
                    <v-app-bar-title>Line通知設定</v-app-bar-title>
                </v-app-bar>
                <v-card-text class="mt-5">
                    <v-row>
                        <v-col cols="12">
                            <v-select label="デバイス" outlined v-model="editedItem.device_id" :items="deviceOptions" :rules="[rules.required]" hide-details></v-select>
                        </v-col>
                        <v-col cols="12">
                            <v-select label="監視項目" outlined v-model="editedItem.sensor_fld" :items="sensorOptions" :rules="[rules.required]" hide-details></v-select>
                        </v-col>
                        <v-col cols="6">
                            <v-select label="上限/下限" outlined v-model="editedItem.upper_lower" :items="upperLowerOptions" :rules="[rules.required]" hide-details></v-select>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="リミット値" outlined v-model="editedItem.limit_val" type="number" :rules="[rules.required]" hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-select label="再通知" outlined v-model="editedItem.silence_hour" :items="silenceOptions" :rules="[rules.required]" hide-details></v-select>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field outlined v-model="editedItem.memo" label="メモ" hide-details></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" outlined @click="dialog = false" width="100">キャンセル</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="submitItem(editedItem)" width="100">OK</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
                <div class="pa-3"></div>
            </v-card>
        </v-dialog>
        <!-- =============================== -->
        <v-dialog v-model="dialogDeleteConfirm" max-width="500px">
            <v-card>
                <v-card-title class="blue darken-1 white--text">
                    <h3>削除</h3>
                </v-card-title>
                <v-card-text class="py-5">
                    <h2>{{ editedItem.sensor_name }} [{{ editedItem.limit_val }}]</h2>
                    <h4>{{ editedItem.upper_lower_name }}</h4>
                    <h4>を削除しますか?</h4>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" outlined @click="dialogDeleteConfirm = false" width="100">キャンセル</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="submitDeleteItem(editedItem)" width="100">削除!!</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- =============================== -->
    </v-container>
</template>

<script>
export default {
    name: "LineNotify",
    //#######################################################
    data() {
        return {
            jwt: "",
            myInfo: [],
            user_id: 0,
            deviceOptions: [],
            dbUser: { id: "", name: "", line_notify_token: "" },
            dbNotificationConfigs: [],
            headers: [
                { text: "デバイス名", value: "device_name" },
                { text: "センサー", value: "sensor_name" },
                { text: "上限/下限", value: "upper_lower_name" },
                { text: "リミット", value: "limit_val" },
                { text: "再通知(時間)", value: "silence_hour" },
                { text: "メモ", value: "memo" },
                { text: "前回通知日時", value: "sent_at" },
            ],
            sensorOptions: [
                { value: "battery", text: "バッテリー電圧" },
                { value: "water_top", text: "表層水温" },
                { value: "water_middle", text: "中層水温" },
                { value: "water_bottom", text: "深層水温" },
                { value: "chlorophyll", text: "表層クロロ" },
                { value: "chlorophyll_middle", text: "中層クロロ" },
                { value: "chlorophyll_bottom", text: "深層クロロ" },

                { value: "salinity", text: "表層実用塩分" },
                { value: "salinity_middle", text: "中層実用塩分" },
                { value: "salinity_bottom", text: "深層実用塩分" },

                { value: "salinity_sg", text: "表層塩分比重" },
                { value: "salinity_sg_middle", text: "中層塩分比重" },
                { value: "salinity_sg_bottom", text: "深層塩分比重" },

                { value: "oxygen", text: "表層DO" },
                { value: "oxygen_middle", text: "中層DO" },
                { value: "oxygen_bottom", text: "深層DO" },
                { value: "harmful", text: "表層 有害プランクトン" },
                { value: "harmful_middle", text: "中層 有害プランクトン" },
                { value: "harmful_bottom", text: "深層 有害プランクトン" },

                { value: "current_top", text: "表層流速" },
                { value: "current_middle", text: "中層流速" },
                { value: "current_bottom", text: "深層流速" },

                { value: "motor_overcurrent", text: "モーター過負荷" },
                { value: "modified", text: "アライブ間隔(時間)" },
            ],
            upperLowerOptions: [
                { value: "upper", text: "上限設定" },
                { value: "lower", text: "下限設定" },
            ],
            silenceOptions: [
                { value: 1, text: "1時間" },
                { value: 2, text: "2時間" },
                { value: 4, text: "4時間" },
                { value: 8, text: "8時間" },
                { value: 12, text: "12時間" },
                { value: 24, text: "24時間" },
                { value: 48, text: "48時間(2日)" },
                { value: 72, text: "72時間(3日)" },
                { value: 96, text: "96時間(4日)" },
                { value: 120, text: "120時間(5日)" },
                { value: 144, text: "144時間(6日)" },
                { value: 168, text: "168時間(7日)" },
            ],
            //-------------------------
            dialog: false,
            dialogDeleteConfirm: false,
            editedIndex: -1,
            editedItem: {
                id: 0,
                user_id: "",
                device_id: "",
                sensor_fld: "",
                upper_lower: "",
                limit_val: "",
                silence_hour: "",
                line_notify_token: "",
                memo: "",
                modified: "",
            },
            //-------------------------
            rules: {
                //required: value => !!value,
                required: (value) => !!value || "必須項目です",
                //email: value => /.+@.+\\..+/.test(value) || 'メールアドレスの形式が正しくありません',
                //tel: value => /^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/.test(value.replace(/[━.*‐.*―.*－.*\-.*ー.*\-]/gi, ''))|| '電話番号の形式が正しくありません',
                //max: value => (value && value.length <= 100) || '100文字以下で入力してください',
            },
        };
    },
    //#######################################################
    created: async function () {
        this.jwt = this.$localStorage.get("jwt");
        if (!this.jwt) {
            this.$router.push({ name: "Login" });
            return;
        }
        this.myInfo = this.$jwt.decode(this.jwt);
        if (this.myInfo && this.myInfo.exp < this.$moment().unix()) {
            this.$router.push({ name: "Login" });
            return;
        }
        this.user_id = this.myInfo.id;
        await this.axiosGetUsers();
        await this.axiosGetDevices();
        await this.getNotificationConfigs();
        //----------------------------
        window.scrollTo(0, 0);
    },
    //#######################################################
    methods: {
        //====================================================
        async axiosGetUsers() {
            await this.axios({
                method: "GET",
                url: "/web/api/users",
                params: {
                    //site_id: this.myInfo.id,
                    //"_order[id]": "asc",
                },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                if (response.data.json[0]) this.dbUser = response.data.json[0];
            });
        },
        //====================================================
        async axiosGetDevices() {
            //----------------------------------------------
            let rating = this.$localStorage.get("rating");
            rating = rating ? JSON.parse(rating) : {};
            //----------------------------------------------
            await this.axios({
                method: "GET",
                url: "/web/api/devices",
                params: {
                    //site_id: this.myInfo.id,
                    "_order[name]": "asc",
                },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                this.dbDevices = response.data.json;
                //--------------------------
                // お気に入りの並べ替え準備
                for (let idx in this.dbDevices) {
                    let device_id = this.dbDevices[idx]["id"];
                    this.dbDevices[idx]["rating"] = rating[device_id] ? rating[device_id] : 0;
                }
                //--------------------------
                // お気に入り順に、並べ替え
                this.dbDevices.sort(function (a, b) {
                    let ret = 0;
                    if (a.rating > b.rating) ret = -1;
                    if (a.rating < b.rating) ret = 1;
                    if (a.rating == b.rating) ret = 0;
                    return ret;
                });
                //--------------------------
                // サイト選択用Options
                this.deviceOptions = [];
                for (let i in this.dbDevices) {
                    this.deviceOptions.push({
                        value: this.dbDevices[i].id,
                        text: this.dbDevices[i].name,
                    });
                }
                //--------------------------
            });
        },
        //====================================================
        async getNotificationConfigs() {
            await this.axios({
                method: "GET",
                url: "/web/api/notification_configs",
                params: { user_id: this.user_id },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                this.dbNotificationConfigs = response.data.json;
                for (let i in this.dbNotificationConfigs) {
                    let device_id = this.dbNotificationConfigs[i].device_id;
                    this.dbNotificationConfigs[i]["device_name"] = this.deviceName(device_id);
                }
                for (let i in this.dbNotificationConfigs) {
                    let fld = this.dbNotificationConfigs[i].sensor_fld;
                    this.dbNotificationConfigs[i]["sensor_name"] = this.sensorName(fld);
                }
                for (let i in this.dbNotificationConfigs) {
                    let fld = this.dbNotificationConfigs[i].upper_lower;
                    this.dbNotificationConfigs[i]["upper_lower_name"] = this.upperLowerName(fld);
                }
                for (let i in this.dbNotificationConfigs) {
                    let val = this.dbNotificationConfigs[i].sent_at;
                    if (val) this.dbNotificationConfigs[i]["sent_at"] = this.$moment(val).format("YYYY-MM-DD HH:mm");
                }
            });
        },
        //====================================================
        deviceName(device_id) {
            let device_name = "";
            for (let i in this.dbDevices) {
                if (device_id == this.dbDevices[i].id) device_name = this.dbDevices[i].name;
            }
            return device_name;
        },
        //====================================================
        sensorName(fld) {
            let ret = "";
            for (let i in this.sensorOptions) {
                let code = this.sensorOptions[i].value;
                if (fld == code) ret = this.sensorOptions[i].text;
            }
            return ret;
        },
        //====================================================
        upperLowerName(fld) {
            let ret = "";
            for (let i in this.upperLowerOptions) {
                let code = this.upperLowerOptions[i].value;
                if (fld == code) ret = this.upperLowerOptions[i].text;
            }
            return ret;
        },
        //====================================================
        editItemDialog(item) {
            if (!item) {
                this.editedIndex = -1;
                this.editedItem = {
                    id: 0,
                    user_id: this.dbUser.id,
                    device_id: "",
                    sensor_fld: "",
                    upper_lower: "",
                    limit_val: "",
                    silence_hour: "",
                    line_notify_token: this.dbUser.line_notify_token,
                    memo: "",
                    modified: "",
                };
            } else {
                this.editedIndex = this.dbNotificationConfigs.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.editedItem.line_notify_token = this.dbUser.line_notify_token;
            }
            this.dialog = true;
        },
        //====================================================
        async submitItem(item) {
            let ErrCount = 0;
            if (!item.user_id) ErrCount++;
            if (!item.device_id) ErrCount++;
            if (!item.sensor_fld.trim()) ErrCount++;
            if (!item.upper_lower.trim()) ErrCount++;
            if (!item.limit_val) ErrCount++;
            if (!item.silence_hour) ErrCount++;
            if (!item.line_notify_token.trim()) ErrCount++;
            if (ErrCount) {
                alert("未入力項目があります。");
                return false;
            }
            //-----------------------------------
            const axiosData = {
                user_id: item.user_id,
                device_id: item.device_id,
                sensor_fld: item.sensor_fld.trim(),
                upper_lower: item.upper_lower.trim(),
                limit_val: item.limit_val,
                silence_hour: item.silence_hour,
                line_notify_token: item.line_notify_token.trim(),
                memo: item.memo.trim(),
                modified: this.$moment().format("YYYY-MM-DD HH:mm:ss"),
            };
            //-----------------------------------
            let restMethod = "POST";
            let restUrl = "/web/api/notification_configs";
            if (item.id > 0) restMethod = "PUT";
            if (item.id > 0) restUrl = restUrl + "/" + item.id;
            //-----------------------------------
            await this.axios({
                method: restMethod,
                url: restUrl,
                headers: { Authorization: "Bearer " + this.jwt },
                data: axiosData,
                timeout: 30000,
            }).then((response) => {
                if (response.data.json) {
                    //self.console.log(response.data.json);
                    this.dialog = false;
                    this.getNotificationConfigs();
                }
            });
        },
        //====================================================
        deleteItemConfirmDialog(item) {
            this.editedIndex = this.dbNotificationConfigs.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDeleteConfirm = true;
        },
        //====================================================
        async submitDeleteItem(item) {
            this.isLoading = true;
            const PrimaryKey = item.id;
            await this.axios({
                method: "DELETE",
                url: "/web/api/notification_configs/" + PrimaryKey,
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then(() => {
                this.getNotificationConfigs();
                this.dialogDeleteConfirm = false;
            });
            this.isLoading = false;
        },
        //====================================================
    },
    //#######################################################
};
</script>
