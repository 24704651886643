<template>
    <div id="chart--wrapper" class="my-5"></div>
</template>

<script>
import * as d3 from "d3";
export default {
    name: "FlowArrows",
    //#######################################################
    props: {
        degCurrentData: [],
    },
    //#######################################################
    data() {
        return {
            width: 400,
            height: 300,
        };
    },
    //#######################################################
    mounted() {
        this.main();
    },
    //#######################################################
    watch: {
        degCurrentData: async function () {
            this.main();
        },
    },
    //#######################################################
    methods: {
        //===================================================
        async main() {
            //--------------------------------------
            // D3.js用のDOM取得
            const contents = d3.select("#chart--wrapper");
            contents.selectAll("svg").remove();
            const svg = contents.append("svg");
            //svg.attr("width", this.width).attr("height", this.height);
            //svg.attr("viewBox", "0 0 600 800").attr("preserveAspectRatio", "xMidYMid");
            //--------------------------------------
            // 四角を描く
            // svg.append("rect").attr("width", 30).attr("height", 30).attr("x", 100).attr("y", 100).attr("stroke", "black").attr("fill", "transparent");
            //--------------------------------------
            // 1. データを取得
            const data = await this.getData();
            // 2. データを整形する
            const dataset = this.filterData(data);
            // 3. グラフの枠を準備する
            const scale = this.setupGraph(contents, svg, dataset);
            // 4. 折れ線グラフを描画
            const color = d3.rgb("#8a2be2"); //("#a785cc");
            this.drawLine(svg, dataset, scale, color);
        },
        //===================================================
        getData() {
            //------------------------------------
            const ret = this.degCurrentData.map((d) => {
                return {
                    date: d.date,
                    current: d.current,
                    degree: d.deg,
                };
            });
            return ret;
            //------------------------------
            // テストデータ
            //------------------------------
            // return [
            //     { date: "2023-08-01 00:00", current: null, degree: null },
            //     { date: "2023-08-02 00:00", current: null, degree: null },
            // ];
            //------------------------------
            // テストデータ
            //------------------------------
            // return [
            //     { date: "2023-08-25 00:00", current: 30, degree: 20 },
            //     { date: "2023-08-25 01:00", current: 30, degree: 40 },
            //     { date: "2023-08-25 02:00", current: 30, degree: 60 },
            //     { date: "2023-08-25 03:00", current: 30, degree: 80 },
            //     { date: "2023-08-25 04:00", current: 30, degree: 100 },
            //     { date: "2023-08-25 05:00", current: 30, degree: 120 },
            //     { date: "2023-08-25 06:00", current: 30, degree: 140 },
            //     { date: "2023-08-25 07:00", current: 30, degree: 160 },
            //     { date: "2023-08-25 08:00", current: 30, degree: 180 },
            //     { date: "2023-08-25 09:00", current: 30, degree: 200 },
            //     { date: "2023-08-25 10:00", current: 30, degree: 220 },
            //     { date: "2023-08-25 11:00", current: 30, degree: 240 },
            //     { date: "2023-08-25 12:00", current: 30, degree: 260 },
            //     { date: "2023-08-25 13:00", current: 30, degree: 280 },
            //     { date: "2023-08-25 14:00", current: 30, degree: 300 },
            //     { date: "2023-08-25 15:00", current: 30, degree: 320 },
            //     { date: "2023-08-25 16:00", current: 30, degree: 340 },
            //     { date: "2023-08-25 17:00", current: 30, degree: 360 },
            //     { date: "2023-08-25 18:00", current: 30, degree: 380 },
            //     { date: "2023-08-25 19:00", current: 30, degree: 400 },
            //     { date: "2023-08-25 20:00", current: 30, degree: 420 },
            //     { date: "2023-08-25 21:00", current: 30, degree: 440 },
            //     { date: "2023-08-25 22:00", current: 30, degree: 460 },
            //     { date: "2023-08-25 23:00", current: 30, degree: 480 },
            //     { date: "2023-08-26 00:00", current: 0, degree: 500 },
            // ];
        },
        //===================================================
        filterData(data) {
            const timeparser = d3.timeParse("%Y-%m-%d %H:%M");
            return data
                .map((d) => {
                    const current = d.current;
                    let degree = d.degree;
                    degree = degree - 270;
                    const radian = degree * (Math.PI / 180); // Degree --> Radian
                    let xVal = Math.cos(radian) * current;
                    let yVal = Math.sin(radian) * current;
                    return {
                        date: timeparser(d.date),
                        value: Number(d.current),
                        current: Number(d.current),
                        degree: Number(d.degree),
                        xVal: xVal,
                        yVal: yVal,
                    };
                })
                .filter((d) => d.date != null); // 不要なデータを除外
        },
        //===================================================
        setupGraph(contents, svg, dataset) {
            const padding = 40;
            // グラフの幅（X軸）
            const width = contents.node().clientWidth - padding;

            // グラフの高さ（Y軸）
            const height = contents.node().clientHeight - padding;

            // gはグループです。SVGで他の要素をグループ化する際に利用するタグです
            // グループをXとY、両方に追加します。
            const x = svg.append("g").attr("class", "axis axis-x").attr("stroke-width", 2);
            const y = svg.append("g").attr("class", "axis axis-y").attr("stroke-width", 2);
            x.style("font-size", "12px");
            y.style("font-size", "12px");

            // X軸に関する設定です
            const xScale = d3
                // 時間ベースの軸であることを定義します
                .scaleTime()
                // 最低値、最大値の設定です
                .domain([
                    d3.min(dataset.map((d) => d.date)), // 日付の配列を渡せば、d3.minで最小値を出してくれます
                    d3.max(dataset.map((d) => d.date)), // 日付の配列を渡せば、d3.maxで最大値を出してくれます
                ])
                // 大きさを指定します
                .range([padding, width]);

            // Y軸に関する設定です
            const yScale = d3
                // 数値ベースの軸であることを定義します
                .scaleLinear()
                // 最低値、最大値の設定です
                .domain([
                    //0,
                    (d3.max(dataset.map((d) => d.value)) + 0) * -1, // 値の配列を渡せば、d3.maxで最大値を出してくれます
                    d3.max(dataset.map((d) => d.value)) + 0, // 値の配列を渡せば、d3.maxで最大値を出してくれます
                ])
                // 大きさを指定します
                .range([height, padding]);

            // 表示する際のフォーマットです（YYYY/MMという表示です）
            //const format = d3.timeFormat("%m/%d %H:%M");
            const format = d3.timeFormat("%d日%H時");
            // X軸の値の個数です
            const xTicks = 4;
            // X軸の記述設定です
            const axisx = d3.axisBottom(xScale).ticks(xTicks).tickFormat(format);
            // Y軸の記述設定です
            const axisy = d3.axisLeft(yScale);

            // SVGに描画します
            x.attr("transform", "translate(" + 0 + "," + height + ")").call(axisx);
            y.attr("transform", "translate(" + padding + "," + 0 + ")").call(axisy);

            //--------------------------------
            // 真ん中に0の線を引く
            //--------------------------------
            if (dataset.length > 0) {
                const xMin = d3.min(dataset.map((d) => d.date));
                const xMax = d3.max(dataset.map((d) => d.date));
                svg.append("line")
                    .attr("stroke", "black")
                    .attr("stroke-width", 1)
                    //.attr("fill", "none")
                    .attr("x1", xScale(xMin))
                    .attr("x2", xScale(xMax))
                    .attr("y1", yScale(0))
                    .attr("y2", yScale(0));
            }
            //--------------------------------
            // xScale/yScaleはこの後も使うので返却します
            return { x: xScale, y: yScale };
        },
        //===================================================
        drawLine(svg, dataset, scale, color) {
            //------------------------------------
            // 矢印を作成
            svg.append("defs").append("marker").attr("id", "arrowhead1").attr("refX", 1.5).attr("refY", 1.5).attr("markerWidth", 3).attr("markerHeight", 3).attr("orient", "auto").append("path").attr("fill", color).attr("d", "M 0 0 L 0 3 L 3 1.5 z");
            //------------------------------------
            for (let obj of dataset) {
                if (!obj.date) continue;
                if (!obj.xVal) continue;
                if (!obj.yVal) continue;
                svg.datum(obj)
                    .append("line")
                    .attr("stroke", color)
                    .attr("stroke-width", 2)
                    .attr("fill", "none")
                    .attr("x1", (d) => scale.x(d.date))
                    .attr("x2", (d) => scale.x(d.date) + (scale.y(d.xVal) - scale.y(0)))
                    .attr("y1", scale.y(0))
                    .attr("y2", (d) => scale.y(d.yVal))
                    .attr("marker-end", "url(#arrowhead1)"); //線に矢印を付加
            }
            //------------------------------------
        },
        //===================================================
    },
};
</script>

<style>
#chart--wrapper {
    /* height: 600px; */
    height: 100%;
}
#chart--wrapper svg {
    width: 100%;
    height: 100%;
}
</style>
<!--
-->
