<template>
    <v-container class="pa-0">
        <v-overlay :value="isLoading" style="z-index: 1002">
            <v-progress-circular :size="70" :width="7" indeterminate></v-progress-circular>
        </v-overlay>
        <!-- ============================================= -->
        <l-map ref="map" :style="mapCSS()" :zoom="zoom" :minZoom="6" :center="[center.lat, center.lng]" @baselayerchange="baselayerChanged" @update:zoom="zoomUpdated" @update:center="centerUpdated" :options="{ zoomControl: false }">
            <!--
            <l-tile-layer :url="url"></l-tile-layer>
            -->
            <l-tile-layer v-for="tileProvider in tileProviders" :key="tileProvider.name" :name="tileProvider.name" :visible="tileProvider.visible" :url="tileProvider.url" layer-type="base" :attribution="tileProvider.attribution" />
            <l-control-layers position="bottomright"></l-control-layers>
            <!-- ---------------- -->
            <l-control position="topright">
                <v-btn elevation="3" @click.stop="drawer = !drawer">
                    <v-icon color="grey darken-3">mdi-cog</v-icon>
                </v-btn>
            </l-control>
            <!-- ---------------- -->
            <l-control position="bottomleft">
                <v-sheet class="pa-1" v-show="colorTitle.length">
                    <h3 class="text-center">{{ colorTitle }}</h3>
                    <v-img :src="colorBarImg" width="250"></v-img>
                    <v-row>
                        <v-col>
                            <span>{{ colorRangeMin }}</span>
                        </v-col>
                        <v-col class="text-center">
                            <span>{{ (colorRangeMax - colorRangeMin) / 2 }}</span>
                        </v-col>
                        <v-col class="text-right">
                            <span>{{ colorRangeMax }}</span>
                        </v-col>
                    </v-row>
                </v-sheet>
            </l-control>
            <!-- ---------------- -->
            <l-marker v-for="(p, idx) in markers" :key="'M' + idx" :lat-lng="[p.lat, p.lng]">
                <!-- <l-popup :content="p.device_name">aaaaa</l-popup> -->
                <l-popup>
                    <h2>{{ p.device_name }}</h2>

                    <v-btn class="ma-1" :to="{ name: 'DeviceChart', params: { device_id: p.device_id } }" color="success">
                        <v-icon>mdi-chart-line</v-icon>
                    </v-btn>
                    <v-btn class="ma-1" :to="{ name: 'DeviceImage', params: { device_id: p.device_id } }" color="yellow">
                        <v-icon>mdi-camera-outline</v-icon>
                    </v-btn>
                    <v-btn class="ma-1" :to="{ name: 'Playback', params: { device_id: p.device.id } }" color="error" v-show="p.device.has_videocam">
                        <v-icon>mdi-video-outline</v-icon>
                    </v-btn>
                </l-popup>
            </l-marker>
            <!-- ---------------- -->
            <l-circle v-for="(p, idx) in circles" :key="'C' + idx" :lat-lng="[p.lat, p.lng]" :color="p.color" :radius="G_radius" :weight="p.weight" :opacity="p.opacity" :fillOpacity="p.fillOpacity" :fillColor="p.fillColor">
                <l-popup>
                    <h3>{{ p.device_name }}</h3>
                    <h2>{{ p.label }}：{{ p.value }}</h2>
                    <v-btn class="ma-1" :to="{ name: 'DeviceChart', params: { device_id: p.device_id } }" color="success">
                        <v-icon>mdi-chart-line</v-icon>
                    </v-btn>
                    <v-btn class="ma-1" :to="{ name: 'DeviceImage', params: { device_id: p.device_id } }" color="yellow">
                        <v-icon>mdi-camera-outline</v-icon>
                    </v-btn>
                    <v-btn class="ma-1" :to="{ name: 'Playback', params: { device_id: p.device.id } }" color="error" v-show="p.device.has_videocam">
                        <v-icon>mdi-video-outline</v-icon>
                    </v-btn>
                    <h3>{{ p.modified }}</h3>
                </l-popup>
            </l-circle>
            <!-- ========================================================= -->
            <v-navigation-drawer v-model="drawer" right absolute temporary style="z-index: 1001" color="grey lighten-3" width="300">
                <div class="ma-3">
                    <h3>表示項目</h3>
                    <v-card class="mx-auto my-1">
                        <v-card-title>GPS</v-card-title>
                        <v-card-text>
                            <v-btn color="success" class="mx-1" @click="markerRegist('pin')">ピン表示</v-btn>
                        </v-card-text>
                    </v-card>
                    <v-card class="mx-auto my-1">
                        <v-card-title>水温</v-card-title>
                        <v-card-text>
                            <v-btn color="success" class="mx-1" @click="markerRegist('water_top')">表層</v-btn>
                            <v-btn color="success" class="mx-1" @click="markerRegist('water_middle')">中層</v-btn>
                            <v-btn color="success" class="mx-1" @click="markerRegist('water_bottom')">深層</v-btn>
                        </v-card-text>
                    </v-card>
                    <v-card class="mx-auto my-1">
                        <v-card-title>クロロフィル</v-card-title>
                        <v-card-text>
                            <v-btn color="success" class="mx-1" @click="markerRegist('chlorophyll')">表層</v-btn>
                            <v-btn color="success" class="mx-1" @click="markerRegist('chlorophyll_middle')">中層</v-btn>
                            <v-btn color="success" class="mx-1" @click="markerRegist('chlorophyll_bottom')">深層</v-btn>
                        </v-card-text>
                    </v-card>
                    <v-card class="mx-auto my-1">
                        <v-card-title>塩分</v-card-title>
                        <v-card-text>
                            <v-btn color="success" class="mx-1" @click="markerRegist('salinity')">表層</v-btn>
                            <v-btn color="success" class="mx-1" @click="markerRegist('salinity_middle')">中層</v-btn>
                            <v-btn color="success" class="mx-1" @click="markerRegist('salinity_bottom')">深層</v-btn>
                        </v-card-text>
                    </v-card>
                    <v-card class="mx-auto my-1">
                        <v-card-title>溶存酸素</v-card-title>
                        <v-card-text>
                            <v-btn color="success" class="mx-1" @click="markerRegist('oxygen')">表層</v-btn>
                            <v-btn color="success" class="mx-1" @click="markerRegist('oxygen_middle')">中層</v-btn>
                            <v-btn color="success" class="mx-1" @click="markerRegist('oxygen_bottom')">深層</v-btn>
                        </v-card-text>
                    </v-card>
                </div>
            </v-navigation-drawer>
            <!-- ========================================================= -->
        </l-map>
    </v-container>
</template>

<style></style>

<script>
import colormap from "colormap";
import { LMap, LTileLayer, LMarker, LCircle, LControl, LPopup, LControlLayers } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";

import L from "leaflet";
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    markerColor: "orange",
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
    name: "DevicesMap",
    components: { LMap, LTileLayer, LMarker, LCircle, LControl, LPopup, LControlLayers },
    //========================================================
    data() {
        return {
            jwt: "",
            myInfo: [],
            dbSites: [],
            dbDevices: [],
            dbLastSensings: [],
            //---------------------
            zoomRadius: [100, 100, 100, 100, 100, 100, 20000, 10000, 5000, 3000, 1000, 800, 600, 400, 200, 100, 50, 25, 10, 100, 100],
            G_radius: 800,
            //---------------------
            colors: null,
            colorBarImg: require("@/assets/color-bar.png"),
            colorRangeMin: 0, // 水温（度）
            colorRangeMax: 50, // 水温（度）
            colorTitle: "",
            //---------------------
            drawer: false,
            //---------------------
            tileProviders: [
                {
                    name: "OpenStreet",
                    visible: true,
                    attribution: '<a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a>',
                    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
                },
                {
                    name: "標準地図",
                    visible: false,
                    attribution: '<a target="_blank" href="https://maps.gsi.go.jp/development/ichiran.html">国土地理院</a>',
                    url: "https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png",
                },
                {
                    name: "衛星写真",
                    visible: false,
                    attribution: '<a target="_blank" href="https://maps.gsi.go.jp/development/ichiran.html">国土地理院</a>',
                    url: "https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg",
                },
                {
                    name: "白地図",
                    visible: false,
                    attribution: '<a target="_blank" href="https://maps.gsi.go.jp/development/ichiran.html">国土地理院</a>',
                    url: "https://cyberjapandata.gsi.go.jp/xyz/blank/{z}/{x}/{y}.png",
                },
                // {
                //     name: "GoogleMap",
                //     visible: false,
                //     url: "https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}"
                // },
                // {
                //     name: "GoogleMap",
                //     visible: false,
                //     url: "https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                // }
            ],
            //---------------------
            //url: "https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg",
            url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
            center: {
                lat: 36.8444607407,
                lng: 138.273925,
            },
            zoom: 5,
            markers: [
                //{ lat: 34.2692895, lng: 136.5897455, name: "真珠ようしょく" },
                //{ lat: 34.2825895, lng: 136.776867, name: "定置あみ" }
            ],
            circles: [],
            //---------------------
            windowWidth: window.innerWidth, // 画面サイズ
            windowHeight: window.innerHeight, //画面サイズ
            modalDevice: {
                id: 0,
                name: "",
                jpgImg: "",
                date: "",
            },
            aspect: "16:9",
            isLoading: false,
        };
    },
    //========================================================
    created: async function () {
        //---------------------------------
        this.jwt = this.$localStorage.get("jwt");
        if (!this.jwt) {
            this.$router.push({ name: "Login" });
            return;
        }
        this.myInfo = this.$jwt.decode(this.jwt);
        if (this.myInfo && this.myInfo.exp < this.$moment().unix()) {
            this.$router.push({ name: "Login" });
            return;
        }
        //---------------------------------
        if (this.$localStorage.get("map.zoom")) this.zoom = Number(this.$localStorage.get("map.zoom"));
        if (this.$localStorage.get("map.lat")) this.center.lat = Number(this.$localStorage.get("map.lat"));
        if (this.$localStorage.get("map.lng")) this.center.lng = Number(this.$localStorage.get("map.lng"));
        if (this.$localStorage.get("map.layer")) {
            let layerName = this.$localStorage.get("map.layer");
            for (let layerObj of this.tileProviders) {
                if (layerObj.name == layerName) layerObj.visible = true;
                else layerObj.visible = false;
            }
        }
        //---------------------------------
        this.colors = colormap({
            colormap: "jet",
            nshades: 410,
            format: "hex",
            alpha: 1,
        });
        //---------------------------------
        this.isLoading = true;
        //---------------------------------
        //非同期でAxiosGet
        const promiseSite = this.getSites();
        const promiseDevice = this.getDevices();
        const promiseSensor = this.getLastSensings();
        //-----------------------
        //上記の終了を待つ
        await promiseSite;
        await promiseDevice;
        await promiseSensor;
        //-----------------------
        this.markerRegist("pin");
        this.isLoading = false;
        //---------------------------
        window.scrollTo(0, 0);
    },
    //========================================================
    watch: {},
    //========================================================
    mounted: function () {
        window.addEventListener("resize", this.handleResize);
    },
    //====================================================
    beforeDestroy: function () {
        window.removeEventListener("resize", this.handleResize);
    },
    //====================================================
    methods: {
        //====================================================
        baselayerChanged: function (layer) {
            //self.console.log(layer.name);
            this.$localStorage.set("map.layer", layer.name);
        },
        zoomUpdated: function (zoom) {
            //self.console.log(zoom);
            this.$localStorage.set("map.zoom", zoom);
            this.G_radius = this.zoomRadius[zoom];
        },
        centerUpdated: function (center) {
            //self.console.log(center);
            this.$localStorage.set("map.lat", center.lat);
            this.$localStorage.set("map.lng", center.lng);
        },
        //====================================================
        mapCSS: function () {
            // return "height: 500px; width: 300px;";
            //-----------------------------------
            let h = this.windowHeight;
            h = h - 60;
            if (h < 300) h = 300;
            return "height: " + h + "px;";
        },
        //====================================================
        handleResize: function () {
            this.windowWidth = window.innerWidth;
            this.windowHeight = window.innerHeight;
        },
        //====================================================
        async getSites() {
            await this.axios({
                method: "GET",
                url: "/web/api/sites",
                // params: { "_order[sort_no]": "desc" },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                this.dbSites = response.data.json;
            });
        },
        //====================================================
        async getDevices() {
            await this.axios({
                method: "GET",
                url: "/web/api/devices",
                // params: { is_active: 1, "_order[id]": "asc" },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                this.dbDevices = response.data.json;
            });
        },
        //====================================================
        async getLastSensings() {
            await this.axios({
                method: "GET",
                url: "/web/api/last_sensings",
                params: { "modified[GREAT]": this.$moment().add(-90, "minutes").format("YYYY-MM-DD HH:mm") },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                this.dbLastSensings = response.data.json;
            });
        },
        //====================================================
        async markerRegist(target) {
            this.drawer = false;
            this.isLoading = true;
            await new Promise((r) => setTimeout(r, 1000));
            //-------------------------------
            const zoom = Number(this.$localStorage.get("map.zoom"));
            const radius = this.zoomRadius[zoom];
            this.G_radius = this.zoomRadius[zoom];
            //-------------------------------
            if (["pin"].includes(target)) {
                this.colorTitle = "";
            }
            if (["water_top", "water_middle", "water_bottom"].includes(target)) {
                this.colorRangeMin = 0; // 水温（度）
                this.colorRangeMax = 50; // 水温（度）
                this.colorTitle = "水温";
                if (target == "water_top") this.colorTitle = "表層水温";
                if (target == "water_middle") this.colorTitle = "中層水温";
                if (target == "water_bottom") this.colorTitle = "深層水温";
            }
            if (["chlorophyll", "chlorophyll_middle", "chlorophyll_bottom"].includes(target)) {
                this.colorRangeMin = 0;
                this.colorRangeMax = 10;
                this.colorTitle = "クロロフィル";
                if (target == "chlorophyll") this.colorTitle = "表層クロロフィル";
                if (target == "chlorophyll_middle") this.colorTitle = "中層クロロフィル";
                if (target == "chlorophyll_bottom") this.colorTitle = "深層クロロフィル";
            }
            if (["salinity", "salinity_middle", "salinity_bottom"].includes(target)) {
                this.colorRangeMin = 0;
                this.colorRangeMax = 40;
                this.colorTitle = "塩分";
                if (target == "salinity") this.colorTitle = "表層塩分";
                if (target == "salinity_middle") this.colorTitle = "中層塩分";
                if (target == "salinity_bottom") this.colorTitle = "深層塩分";
            }
            if (["oxygen", "oxygen_middle", "oxygen_bottom"].includes(target)) {
                this.colorRangeMin = 0;
                this.colorRangeMax = 10;
                this.colorTitle = "溶存酸素";
                if (target == "oxygen") this.colorTitle = "表層溶存酸素";
                if (target == "oxygen_middle") this.colorTitle = "中層溶存酸素";
                if (target == "oxygen_bottom") this.colorTitle = "深層溶存酸素";
            }
            //---------------------------------
            this.colors = colormap({
                colormap: "jet",
                nshades: (this.colorRangeMax - this.colorRangeMin) * 10, // 0.1度単位で色を変えたいのでカラー数は10倍
                format: "hex",
                alpha: 1,
            });
            //-------------------------------
            this.markers = [];
            this.circles = [];
            //-------------------------------
            for (let idx in this.dbDevices) {
                let gps = this.dbDevices[idx].gps;
                let device_id = this.dbDevices[idx].id;
                let device_name = this.dbDevices[idx].name;
                if (gps) {
                    let latlon = gps.split(",");
                    let lat = Number(latlon[0]);
                    let lng = Number(latlon[1]);
                    //-------------------------------
                    // ビン表示
                    if (target == "pin") {
                        this.markers.push({
                            lat: lat,
                            lng: lng,
                            device_id: device_id,
                            device_name: device_name,
                            device: JSON.parse(JSON.stringify(this.dbDevices[idx])),
                        });
                    }
                    //-------------------------------
                    // サークル表示
                    if (target != "pin") {
                        let sensorVals = this.getSensorVals(device_id);
                        if (sensorVals) {
                            let label = "";
                            if (target == "water_top") label = "表層水温";
                            if (target == "water_middle") label = "中層水温";
                            if (target == "water_bottom") label = "深層水温";
                            if (target == "chlorophyll") label = "表層クロロ";
                            if (target == "chlorophyll_middle") label = "中層クロロ";
                            if (target == "chlorophyll_bottom") label = "深層クロロ";
                            if (target == "salinity") label = "表層塩分";
                            if (target == "salinity_middle") label = "中層塩分";
                            if (target == "salinity_bottom") label = "深層塩分";
                            if (target == "oxygen") label = "表層溶存酸素";
                            if (target == "oxygen_middle") label = "中層溶存酸素";
                            if (target == "oxygen_bottom") label = "深層溶存酸素";
                            //-----------
                            let sVal = sensorVals[target];
                            if (sVal < 1) continue; // 1度以下は不良データなので表示しない。
                            //-----------
                            const colorRange = (this.colorRangeMax - this.colorRangeMin) * 10;
                            let colorIndex = Math.floor((sVal - this.colorRangeMin) * 10); // 0.1度単位で色を変えるので10倍
                            if (colorIndex < 0) colorIndex = 0;
                            if (colorIndex > colorRange) colorIndex = colorRange - 1;
                            let color = this.colors[colorIndex];

                            let modified = sensorVals["modified"];
                            this.circles.push({
                                lat: lat,
                                lng: lng,
                                radius: radius,
                                weight: 1,
                                color: "#909090",
                                opacity: 1,
                                fillColor: color,
                                fillOpacity: 0.8,
                                label: label,
                                device_id: device_id,
                                device_name: device_name,
                                device: JSON.parse(JSON.stringify(this.dbDevices[idx])),
                                value: sVal,
                                modified: this.$moment(modified).format("YYYY/MM/DD HH:mm"),
                            });
                        }
                    }
                    //-------------------------------
                }
                this.isLoading = false;
            }
        },
        //====================================================
        getSensorVals(device_id) {
            let record = null;
            for (let idx in this.dbLastSensings) {
                if (device_id == this.dbLastSensings[idx].device_id) {
                    record = this.dbLastSensings[idx];
                    break;
                }
            }
            //--------------------------
            // let ret = {
            //     water_top: null,
            //     water_middle: null,
            //     water_bottom: null,
            //     modified: null,
            // };
            // if (record) {
            //     if (this.$moment(record.modified) > this.$moment().add(-90, "minutes")) {
            //         ret.water_top = record.water_top;
            //         ret.water_middle = record.water_middle;
            //         ret.water_bottom = record.water_bottom;
            //         ret.modified = record.modified;
            //     }
            // }
            // return ret;
            //--------------------------
            return record;
        },
        //====================================================
    },
    //========================================================
};
</script>
