<template>
    <v-container>
        <!-- ============================================= -->
        <v-overlay :value="isLoading">
            <v-progress-circular :size="70" :width="7" indeterminate></v-progress-circular>
        </v-overlay>
        <!-- ============================================= -->
        <div class="my-5" v-for="(item, idx) in deviceTree" :key="idx">
            <v-card class="blue darken-3" outlined>
                <v-carousel height="auto" hide-delimiters show-arrows-on-hover :show-arrows="true" :continuous="false">
                    <v-carousel-item>
                        <v-card-text class="white--text">
                            <h3>{{ item.device_name }}</h3>
                        </v-card-text>
                    </v-carousel-item>
                    <v-carousel-item>
                        <v-card-text class="white--text">
                            <h3>{{ item.device_name }}</h3>
                            <div class="text-center">
                                <v-btn class="ma-1" :to="{ name: 'DeviceChart', params: { device_id: item.device_id } }" color="success"><v-icon>mdi-chart-line</v-icon></v-btn>
                                <v-btn class="ma-1" :to="{ name: 'DeviceImage', params: { device_id: item.device_id } }" color="yellow"><v-icon>mdi-camera-outline</v-icon></v-btn>
                                <v-btn class="ma-1" :to="{ name: 'Playback', params: { device_id: item.device_id } }" color="error" v-show="item.device.has_videocam"><v-icon>mdi-video-outline</v-icon></v-btn>
                                <!--
                                <v-btn class="ma-1" color="cyan lighten-2">加速度</v-btn>
                                <v-btn class="ma-1" color="purple lighten-2">自動昇降</v-btn>
                                -->
                            </div>
                        </v-card-text>
                    </v-carousel-item>
                </v-carousel>
                <div class="white chart-container" style="position: relative; width: auto; height: auto; overflow: hidden">
                    <comp-chart-mini :chartDataChild="item.chart" :yRange="{ min: item.yMin, max: item.yMax }" style="height: 150px" />
                </div>
            </v-card>
        </div>
        <!-- ============================================= -->
        <div v-show="loadingMax > loadingCount && isLoading == false">
            <v-btn block @click="buildDeviceTree()" color="success">さらに表示</v-btn>
        </div>
    </v-container>
</template>

<script>
import CompChartMini from "@/components/CompChartMini";
export default {
    name: "DevicesChart",
    components: {
        CompChartMini,
    },
    //========================================================
    data() {
        return {
            jwt: "",
            myInfo: [],
            dbSites: [],
            dbDevices: [],
            dbLastSensings: [],
            dbChartConfigs: [],
            deviceTree: [],
            //------------------
            dbData: {
                xyWaterTop: [],
                xyWaterMiddle: [],
                xyWaterBottom: [],
            },
            chartDataSetWater: { datasets: [] },
            //------------------
            keywordFilter: "",
            loadingCount: 0,
            loadingMax: 0,
            chartColor: {
                waterTop: "#7fd1ea", //"#87ceeb", // skyblue
                waterMiddle: "#386ddd", //"#4169e1", // royalblue
                waterBottom: "#000d88", // "#00008b", // darkblue
            },
            isLoading: false,
        };
    },
    //========================================================
    created: async function () {
        this.jwt = this.$localStorage.get("jwt");
        if (!this.jwt) {
            this.$router.push({ name: "Login" });
            return;
        }
        this.myInfo = this.$jwt.decode(this.jwt);
        if (this.myInfo && this.myInfo.exp < this.$moment().unix()) {
            this.$router.push({ name: "Login" });
            return;
        }
        //-----------------------
        this.isLoading = true;
        //-----------------------
        //非同期でAxiosGet
        const promiseDevice = this.getDevices();
        const promiseSite = this.getSites();
        const promiseConfig = this.getChartConfigs();
        //-----------------------
        //上記の終了を待つ
        await promiseDevice;
        await promiseSite;
        await promiseConfig;
        //-----------------------
        //上記が全て取得終了したタイミングで、配列マージする
        await new Promise((r) => setTimeout(r, 1000));
        await this.buildDeviceTree();
        //-----------------------
        this.isLoading = false;
        //-----------------------
        window.scrollTo(0, 0);
    },
    //========================================================
    methods: {
        //====================================================
        async getSites() {
            await this.axios({
                method: "GET",
                url: "/web/api/sites",
                //params: { "_order[sort_no]": "desc" },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                this.dbSites = response.data.json;
            });
        },
        //====================================================
        async getDevices() {
            await this.axios({
                method: "GET",
                url: "/web/api/devices",
                //params: { "_order[id]": "desc" },
                params: { "_order[name]": "asc" },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                this.dbDevices = response.data.json;
                this.loadingMax = this.dbDevices.length;
            });
        },
        //====================================================
        async getChartConfigs() {
            await this.axios({
                method: "GET",
                url: "/web/api/chart_configs",
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                this.dbChartConfigs = response.data.json;
            });
        },
        //====================================================
        getMinMax(device_id) {
            let min = 0;
            let max = 50;
            for (let obj of this.dbChartConfigs) {
                if (device_id == obj.id) {
                    min = obj.watertemp_min;
                    max = obj.watertemp_max;
                }
            }
            return { min: min, max: max };
        },
        //====================================================
        async buildDeviceTree() {
            this.isLoading = true;
            //----------------------------------------------
            let rating = this.$localStorage.get("rating");
            rating = rating ? JSON.parse(rating) : {};
            //----------------------------------------------
            for (let idx in this.dbDevices) {
                let device_id = this.dbDevices[idx].id;
                this.dbDevices[idx]["rating"] = rating[device_id] ? rating[device_id] : 0;
            }
            //--------------------------------------------------
            // お気に入り順に、並べ替え
            this.dbDevices.sort(function (a, b) {
                let ret = 0;
                if (a.rating > b.rating) ret = -1;
                if (a.rating < b.rating) ret = 1;
                if (a.rating == b.rating) ret = 0;
                return ret;
            });
            //--------------------------------------------------
            let startCount = this.loadingCount;
            let endCount = this.loadingCount + 8;
            this.loadingCount = this.loadingCount + 8;
            for (let idx in this.dbDevices) {
                if (startCount <= idx && idx < endCount) {
                    let device_id = this.dbDevices[idx].id;
                    let device_name = this.dbDevices[idx].name;
                    let deviceRating = rating[device_id] ? rating[device_id] : 0;
                    //--------------------------------------------------
                    await this.getSensorData(device_id);
                    //--------------------------------------------------
                    let { min, max } = this.getMinMax(device_id);
                    //--------------------------------------------------
                    this.deviceTree.push({
                        device_id: device_id,
                        device_name: device_name,
                        rating: deviceRating,
                        chart: JSON.parse(JSON.stringify(this.chartDataSetWater)),
                        device: JSON.parse(JSON.stringify(this.dbDevices[idx])),
                        yMin: min,
                        yMax: max,
                    });
                    //--------------------------------------------------
                }
            }
            //await new Promise((r) => setTimeout(r, 1000));
            this.deviceTree = Object.assign([], this.deviceTree);
            //--------------------------------------------------
            this.isLoading = false;
        },
        //====================================================
        async getSensorData(device_id) {
            //--------------------------------
            let sdays = -1;
            const sdate = this.$moment(this.targetDate).add(sdays, "days").format("YYYY-MM-DD");
            const edate = this.$moment(this.targetDate).add(1, "days").format("YYYY-MM-DD");
            //--------------------------------
            let requestURL = "/web/api/sensor_logs";
            requestURL += "?device_id=" + device_id;
            requestURL += "&arrived_at[EQGREAT]=" + sdate;
            requestURL += "&arrived_at[SMALL]=" + edate;
            //requestURL += "&sensor_grp=" + "water";
            requestURL += "&_order[id]=asc";
            requestURL += "&_fields=sensor_key,sensor_val,arrived_at";
            await this.axios({
                method: "GET",
                url: requestURL,
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                this.dbSensorLogs = response.data.json;
                this.registXYdata(sdate, edate);
                this.registSeaChartData();
            });
        },
        //====================================================
        registXYdata(sdate, edate) {
            const defaultPoint = [
                { x: sdate, y: null },
                { x: edate, y: null },
            ];
            this.dbData.xyWaterTop = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xyWaterMiddle = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xyWaterBottom = JSON.parse(JSON.stringify(defaultPoint));
            for (let i in this.dbSensorLogs) {
                let x = this.dbSensorLogs[i].arrived_at;
                x = this.$moment(x).format("YYYY-MM-DD HH:mm");
                let y = this.dbSensorLogs[i].sensor_val;
                let s = this.dbSensorLogs[i].sensor_key;
                if (s == "top" && y > 3) this.dbData.xyWaterTop.push({ x: x, y: Number(y) });
                if (s == "middle" && y > 3) this.dbData.xyWaterMiddle.push({ x: x, y: Number(y) });
                if (s == "bottom" && y > 3) this.dbData.xyWaterBottom.push({ x: x, y: Number(y) });
            }
        },
        //====================================================
        registSeaChartData() {
            this.chartDataSetWater = { datasets: [] };
            this.chartDataSetWater.datasets.push({
                data: this.dbData.xyWaterTop,
                borderColor: this.chartColor.waterTop, // "skyblue",
                borderWidth: 2,
                fill: false,
            });
            this.chartDataSetWater.datasets.push({
                data: this.dbData.xyWaterMiddle,
                borderColor: this.chartColor.waterMiddle, //"royalblue",
                borderWidth: 2,
                fill: false,
            });
            this.chartDataSetWater.datasets.push({
                data: this.dbData.xyWaterBottom,
                borderColor: this.chartColor.waterBottom, //"darkblue",
                borderWidth: 2,
                fill: false,
            });
        },
        //====================================================
    },
    //========================================================
};
</script>
