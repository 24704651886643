<script>
import { Line, mixins } from "vue-chartjs";

export default {
    extends: [Line],
    mixins: [Line, mixins.reactiveData],
    props: {
        chartOptions: {},
        chartDataChild: {},
    },
    data() {
        return {};
    },
    mounted() {
        this.renderChart(this.chartDataChild, this.chartOptions);
    },
    watch: {
        chartDataChild: function () {
            this.renderChart(this.chartDataChild, this.chartOptions);
        },
        chartOptions: function () {
            this.renderChart(this.chartDataChild, this.chartOptions);
        },
    },
};
</script>
