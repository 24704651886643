<script>
import { Line, mixins } from "vue-chartjs";
//import zoom from "chartjs-plugin-zoom";

export default {
    extends: [Line],
    mixins: [Line, mixins.reactiveData],
    //====================================================================
    props: {
        //chartOptions: {},
        chartDataChild: {},
        yRange: { min: Number, max: Number },
    },
    //====================================================================
    data() {
        return {
            //ymax: "15",
        };
    },
    //====================================================================
    created: function () {
        //this.buildOption();
    },
    //====================================================================
    mounted() {
        //this.addPlugin(zoom);
        this.renderChart(this.chartDataChild, this.chartOptions);
    },
    //====================================================================
    watch: {
        chartDataChild: async function () {
            await this.buildOption();
            await this.renderChart(this.chartDataChild, this.chartOptions);
        },
    },
    //====================================================================
    methods: {
        buildOption() {
            //-------------------------------------------
            // 平均値のプラマイ4度をMin/Maxとする。
            //-------------------------------------------
            // let datasets = JSON.parse(JSON.stringify(this.chartDataChild.datasets));
            // let aryVal = [];
            // for (let i in datasets[0].data) {
            //     let val = datasets[0].data[i].y;
            //     if (val) aryVal.push(val);
            // }
            // for (let i in datasets[1].data) {
            //     let val = datasets[1].data[i].y;
            //     if (val) aryVal.push(val);
            // }
            // const average = Math.floor(aryVal.reduce((acc, cur) => acc + cur) / aryVal.length);
            // this.yRange.min = average - 4;
            // this.yRange.max = average + 4;
            //-------------------------------------------
            this.chartOptions = {
                maintainAspectRatio: false,
                legend: { display: true, position: "bottom" },
                scales: {
                    xAxes: [
                        {
                            type: "time",
                            distribution: "linear", // use 'linear'(default) or 'series'
                            time: { displayFormats: { hour: "D日H時", day: "D日H時", month: "D日H時" } },
                            //ticks: { minRotation: 10, maxRotation: 40, maxTicksLimit: 9 },
                            ticks: { minRotation: 0, maxRotation: 0, maxTicksLimit: 4, fontColor: "#000" },
                            gridLines: { drawOnChartArea: true },
                        },
                    ],
                    yAxes: [
                        {
                            id: "axis-water-temp",
                            display: true,
                            position: "left",
                            scaleLabel: { display: false, labelString: "水温(℃)", fontColor: "navy" },
                            ticks: {
                                fontColor: "navy",
                                min: Number(this.yRange.min) ? Number(this.yRange.min) : 0,
                                max: Number(this.yRange.max) ? Number(this.yRange.max) : undefined,
                            },
                        },
                        {
                            display: false,
                            id: "axis-day-night",
                            ticks: { min: 0, max: 100 },
                        },
                    ],
                },
                tooltips: {
                    // enabled: true,
                    filter: function (item) {
                        let ret = true;
                        //self.console.log({ item });
                        if (item.datasetIndex >= 3) {
                            // 0 = 表層
                            // 1 = 中層
                            // 2 = 深層
                            // 3 = 前年表層
                            // 4 = 前年中層
                            // 5 = 前年深層
                            ret = false;
                        }
                        return ret;
                    },
                    // caretSize: 15,
                    // titleFontSize: 24,
                    // bodyFontSize: 24,
                    // bodyFontStyle: "bold",
                    // xPadding: 18,
                    // yPadding: 12,
                    // backgroundColor: "rgba(0, 0, 0, 0.7)",
                },
                elements: {
                    line: { tension: 0.2 }, // line: { tension: 0.4 },
                    point: { radius: 1, hitRadius: 10, hoverRadius: 1, hoverBorderWidth: 3 },
                },
                animation: false,
                //---- chartjs plugin zoom-----------
                // plugins: {
                //     zoom: {
                //         pan: {
                //             enabled: true,
                //             mode: "y",
                //             threshold: 10,
                //         },
                //         zoom: {
                //             enabled: true,
                //             mode: "y",
                //             wheel: {
                //                 enabled: true,
                //             },
                //             drag: {
                //                 enabled: true,
                //             },
                //             pinch: {
                //                 enabled: true,
                //             },
                //         },
                //     },
                // },
                //---- chartjs plugin zoom-----------
            };
        },
    },
    //====================================================================
};
</script>
